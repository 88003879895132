import Airtable from "airtable";

export function getPageContent(tableName) {
  return new Promise((resolve, reject) => {
    const API_KEY = process.env.VUE_APP_AIRTABLE_API_KEY;
    var base = new Airtable({ apiKey: API_KEY }).base("appn0WDLC3h9ohCW3");
    var pageContents = [];
    base(tableName)
      .select({
        // maxRecords: 100,
        fields: ["Block Order", "Text", "Image"],
        view: "Grid view",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          // This function (`page`) will get called for each page of records.
          records.forEach(function (record) {
            var contentBlock = {};
            contentBlock.order = record.get("Block Order");
            contentBlock.id = record.getId();
            contentBlock.text = record.get("Text");
            contentBlock.image = record.get("Image");
            pageContents.push(contentBlock);
            console.log("Add a contentBlock: ", contentBlock.text);
          });
          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            reject(err);
          } else {
            resolve(pageContents);
          }
        }
      );
  });
}
