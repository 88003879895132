<template>
  <div class="container">
    <div class="about-container">
      <div class="markdown">
        <vue3-markdown-it
          v-for="contentBlock in pageContents"
          :source="contentBlock.text"
          :key="contentBlock.id"
        />
      </div>
      <!-- <h1>Investing in Futures</h1>
      <h3>Future Worlds Simulator</h3>
      <h4>
        A set of worldbuilding decks that invite you to imagine new realities
        and invent a world you want to live in, regardless of how wild,
        impractical, idyllic, sensible or utopian.
      </h4>
      <p>
        Each deck contains categories from which you choose a card. On each card
        is a constraint which can be used to describe an aspect of an imagined
        world. Each deck also includes a set of instructions and prompts that
        you can use to imagine, inhabit, and create.
      </p>
      <p>
        We encourage you to engage in conversation, participatory gaming, and
        craft. The original deck was developed to help you imagine a future that
        you would want to live in, welcoming you to sever the ties to and
        limitations of our current reality.
      </p>
      <p>
        Themed decks have been developed in order to focus on a specific aspect
        or framing of a world (such as waste, or ecology). These cards can be
        used for workshops, creative work, and in classrooms.
      </p> -->
    </div>
  </div>
</template>
<script>
import { getPageContent } from "@/services/PageService.js";
export default {
  name: "About",
  data() {
    return {
      pageContents: [
        // {
        //   order: Number,
        //   id: Number,
        //   text: String,
        //   image: String,
        // },
      ],
    };
  },
  computed: {},
  methods: {
    fetchPageContents() {
      getPageContent("About")
        .then((theData) => {
          this.pageContents = theData;
        })
        .catch((err) => {
          console.log(
            "Oh noes, something went wrong getting page contents: ",
            err
          );
        });
    },
  },
  mounted() {
    this.fetchPageContents();
  },
};
</script>
<style scoped>
.about-container {
  max-width: 60rem;
  margin: 0 auto;
  padding-top: 2rem;
}
</style>
